var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("loading", {
        attrs: {
          active: _vm.isLoading,
          "can-cancel": false,
          "is-full-page": _vm.fullPage,
        },
        on: {
          "update:active": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c(
        "div",
        [
          _vm.isAdmin
            ? _c(
                "b-card",
                { attrs: { "no-body": "" } },
                [
                  _c(
                    "b-tabs",
                    { attrs: { pills: "", card: "" } },
                    [
                      _c(
                        "b-tab",
                        { attrs: { title: "Geral", active: "" } },
                        [
                          _c(
                            "b-card-group",
                            { attrs: { deck: "" } },
                            [
                              _c(
                                "b-card",
                                {
                                  staticClass: "shadow-none",
                                  attrs: {
                                    "no-body": "",
                                    "body-class": "card-body-class",
                                  },
                                  model: {
                                    value: _vm.activeUsers,
                                    callback: function ($$v) {
                                      _vm.activeUsers = $$v
                                    },
                                    expression: "activeUsers",
                                  },
                                },
                                [
                                  _c(
                                    "b-card-header",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center align-items-center",
                                      attrs: {
                                        "header-tag": "header",
                                        "header-class": "card-header-class",
                                      },
                                    },
                                    [_c("h3", [_vm._v("Usuários ativos")])]
                                  ),
                                  _c("b-card-body", {
                                    attrs: { "body-class": "card-body-class" },
                                    domProps: {
                                      textContent: _vm._s(_vm.activeUsers),
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-card",
                                {
                                  staticClass: "shadow-none",
                                  attrs: {
                                    "no-body": "",
                                    "body-class": "card-body-class",
                                  },
                                  model: {
                                    value: _vm.inactiveUsers,
                                    callback: function ($$v) {
                                      _vm.inactiveUsers = $$v
                                    },
                                    expression: "inactiveUsers",
                                  },
                                },
                                [
                                  _c(
                                    "b-card-header",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center align-items-center",
                                      attrs: {
                                        "header-tag": "header",
                                        "header-class": "card-header-class",
                                      },
                                    },
                                    [_c("h3", [_vm._v("Usuários inativos")])]
                                  ),
                                  _c("b-card-body", {
                                    attrs: { "body-class": "card-body-class" },
                                    domProps: {
                                      textContent: _vm._s(_vm.inactiveUsers),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-tab",
                        {
                          attrs: { title: "Bônus" },
                          on: {
                            click: function ($event) {
                              return _vm.onBonusTabClick()
                            },
                          },
                        },
                        [
                          _c(
                            "b-col",
                            { staticClass: "my-1", attrs: { lg: "6" } },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-form-radio-group",
                                    {
                                      attrs: { buttons: "" },
                                      model: {
                                        value: _vm.userRadioBonusSelected,
                                        callback: function ($$v) {
                                          _vm.userRadioBonusSelected = $$v
                                        },
                                        expression: "userRadioBonusSelected",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-radio",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover",
                                              modifiers: { hover: true },
                                            },
                                          ],
                                          attrs: {
                                            value: "user",
                                            title: "Usuário Responsável",
                                          },
                                        },
                                        [_vm._v("Usuário")]
                                      ),
                                      _c(
                                        "b-form-radio",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover",
                                              modifiers: { hover: true },
                                            },
                                          ],
                                          attrs: {
                                            value: "cod",
                                            title: "Código",
                                          },
                                        },
                                        [_vm._v("Código")]
                                      ),
                                      _c(
                                        "b-form-radio",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover",
                                              modifiers: { hover: true },
                                            },
                                          ],
                                          attrs: {
                                            value: "name",
                                            title: "Nome",
                                          },
                                        },
                                        [_vm._v("Nome")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-input-group",
                                    {
                                      staticStyle: {
                                        "{padding-top": "10px",
                                        "margin-top": "10px",
                                      },
                                      attrs: { size: "sm" },
                                    },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        { attrs: { "is-text": "" } },
                                        [
                                          _c("b-icon", {
                                            attrs: { icon: "search" },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("b-form-input", {
                                        attrs: {
                                          type: "search",
                                          placeholder: "Buscar...",
                                        },
                                        on: {
                                          keyup: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            return _vm.onUserBonusSearchClick()
                                          },
                                        },
                                        model: {
                                          value: _vm.filterUserBonusText,
                                          callback: function ($$v) {
                                            _vm.filterUserBonusText = $$v
                                          },
                                          expression: "filterUserBonusText",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("b-table", {
                            attrs: {
                              striped: "",
                              fields: _vm.fields,
                              "current-page": _vm.currentPage,
                              items: _vm.users,
                              "per-page": 0,
                              stacked: "md",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "cell(bonus)",
                                  fn: function (row) {
                                    return [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "item-table",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onBonusClick(
                                                    row.item,
                                                    row.index,
                                                    $event.target
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("+")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              466462566
                            ),
                          }),
                          _c(
                            "b-col",
                            {
                              staticClass: "my-1",
                              attrs: { sm: "7", md: "6" },
                            },
                            [
                              _c("b-pagination", {
                                staticClass: "my-0",
                                attrs: {
                                  "total-rows": _vm.totalRows,
                                  "per-page": _vm.perPage,
                                  align: "fill",
                                  size: "sm",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.updateBonusPage()
                                  },
                                },
                                model: {
                                  value: _vm.currentPage,
                                  callback: function ($$v) {
                                    _vm.currentPage = $$v
                                  },
                                  expression: "currentPage",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-modal",
                            {
                              ref: "modal",
                              attrs: {
                                id: "modal-prevent-closing",
                                "ok-title": "Enviar",
                                "cancel-title": "Cancelar",
                                "cancel-variant": "transparent",
                                title: _vm.modalTitle,
                                size: "md",
                              },
                              on: {
                                hidden: _vm.resetModal,
                                ok: _vm.handleModalOk,
                              },
                            },
                            [
                              _c(
                                "b-form",
                                {
                                  on: {
                                    submit: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.handleSubmit.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "b-row",
                                    {
                                      attrs: {
                                        cols: "3",
                                        "cols-sm": "3",
                                        "cols-md": "4",
                                        "cols-lg": "3",
                                      },
                                    },
                                    [
                                      _c("b-col", {
                                        staticClass: "creditsBalance",
                                        staticStyle: {},
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "b-col",
                                        { staticClass: "mb-2 creditsBalance" },
                                        [_vm._v("Aplicativo ")]
                                      ),
                                      _c(
                                        "b-col",
                                        { staticClass: "creditsBalance" },
                                        [_vm._v("API")]
                                      ),
                                      _c(
                                        "b-col",
                                        { staticClass: "creditsBalance" },
                                        [_vm._v("Crédito Atual ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-col",
                                        { staticClass: "creditsBalanceRight" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              new Date(
                                                _vm.currentCreditAvailable *
                                                  1000
                                              )
                                                .toISOString()
                                                .substring(11, 19)
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        { staticClass: "creditsBalanceRight" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              new Date(
                                                _vm.currentCreditApi * 1000
                                              )
                                                .toISOString()
                                                .substring(11, 19)
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        { staticClass: "creditsBalance" },
                                        [_vm._v("Reservado ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-col",
                                        { staticClass: "creditsBalanceRight" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              new Date(
                                                _vm.currentCreditReserved * 1000
                                              )
                                                .toISOString()
                                                .substring(11, 19)
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        { staticClass: "creditsBalanceRight" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              new Date(
                                                _vm.currentCreditReservedApi *
                                                  1000
                                              )
                                                .toISOString()
                                                .substring(11, 19)
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("br"),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Bônus",
                                                "label-for": "bonus-input",
                                                state: _vm.bonusState,
                                                "invalid-feedback":
                                                  "Não pode ser em branco",
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                staticClass:
                                                  "border border-dark",
                                                attrs: {
                                                  id: "bonus-input",
                                                  placeholder: "Minutos",
                                                  autofocus: "",
                                                },
                                                model: {
                                                  value: _vm.bonus,
                                                  callback: function ($$v) {
                                                    _vm.bonus = $$v
                                                  },
                                                  expression: "bonus",
                                                },
                                              }),
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "10px",
                                                  },
                                                  attrs: { switch: "" },
                                                  model: {
                                                    value: _vm.api,
                                                    callback: function ($$v) {
                                                      _vm.api = $$v
                                                    },
                                                    expression: "api",
                                                  },
                                                },
                                                [_vm._v("API")]
                                              ),
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "10px",
                                                  },
                                                  attrs: { switch: "" },
                                                  model: {
                                                    value: _vm.recurrence,
                                                    callback: function ($$v) {
                                                      _vm.recurrence = $$v
                                                    },
                                                    expression: "recurrence",
                                                  },
                                                },
                                                [_vm._v("Recorrência")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-tab",
                        {
                          attrs: { title: "Extrato PJ" },
                          on: {
                            click: function ($event) {
                              return _vm.onExtractTabClick()
                            },
                          },
                        },
                        [
                          _c(
                            "b-col",
                            { staticClass: "my-1", attrs: { lg: "6" } },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-form-radio-group",
                                    {
                                      attrs: { buttons: "" },
                                      model: {
                                        value: _vm.userRadioExtractSelected,
                                        callback: function ($$v) {
                                          _vm.userRadioExtractSelected = $$v
                                        },
                                        expression: "userRadioExtractSelected",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-radio",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover",
                                              modifiers: { hover: true },
                                            },
                                          ],
                                          attrs: {
                                            value: "cnpj",
                                            title: "CNPJ",
                                          },
                                        },
                                        [_vm._v("CNPJ")]
                                      ),
                                      _c(
                                        "b-form-radio",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover",
                                              modifiers: { hover: true },
                                            },
                                          ],
                                          attrs: {
                                            value: "cod",
                                            title: "Código",
                                          },
                                        },
                                        [_vm._v("Código")]
                                      ),
                                      _c(
                                        "b-form-radio",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover",
                                              modifiers: { hover: true },
                                            },
                                          ],
                                          attrs: {
                                            value: "name",
                                            title: "Nome",
                                          },
                                        },
                                        [_vm._v("Nome")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-input-group",
                                    {
                                      staticStyle: {
                                        "{padding-top": "10px",
                                        "margin-top": "10px",
                                      },
                                      attrs: { size: "sm" },
                                    },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        { attrs: { "is-text": "" } },
                                        [
                                          _c("b-icon", {
                                            attrs: { icon: "search" },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("b-form-input", {
                                        attrs: {
                                          type: "search",
                                          placeholder: "Buscar...",
                                        },
                                        on: {
                                          keyup: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            return _vm.onUserExtractSearchClick()
                                          },
                                        },
                                        model: {
                                          value: _vm.filterUserExtractText,
                                          callback: function ($$v) {
                                            _vm.filterUserExtractText = $$v
                                          },
                                          expression: "filterUserExtractText",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("b-table", {
                            attrs: {
                              striped: "",
                              fields: _vm.corporateFields,
                              "current-page": _vm.currentCorporatePage,
                              items: _vm.corporates,
                              "per-page": 0,
                              stacked: "md",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "cell(info)",
                                  fn: function (row) {
                                    return [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "item-table",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onInfoClick(
                                                    row.item,
                                                    row.index,
                                                    $event.target
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("+")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3816454502
                            ),
                          }),
                          _c(
                            "b-col",
                            {
                              staticClass: "my-1",
                              attrs: { sm: "7", md: "6" },
                            },
                            [
                              _c("b-pagination", {
                                staticClass: "my-0",
                                attrs: {
                                  "total-rows": _vm.totalCorporateRows,
                                  "per-page": _vm.perCorporatePage,
                                  align: "fill",
                                  size: "sm",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.updateCorporatePage()
                                  },
                                },
                                model: {
                                  value: _vm.currentCorporatePage,
                                  callback: function ($$v) {
                                    _vm.currentCorporatePage = $$v
                                  },
                                  expression: "currentCorporatePage",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-modal",
                            {
                              ref: "corporateModal",
                              attrs: {
                                id: "modal-prevent-closing",
                                "ok-title": "Ok",
                                "cancel-variant": "transparent",
                                title: _vm.corporateModalTitle,
                                size: "xl",
                              },
                              on: {
                                hidden: _vm.resetCorporateModal,
                                ok: _vm.handleCorporateModalOk,
                              },
                            },
                            [
                              _c(
                                "b-form",
                                {
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Data inicial",
                                                "label-for": "date-input",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-datepicker",
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      id: "example-datepicker",
                                                      "reset-button": "",
                                                      locale: _vm.locale,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.onDateChange()
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedBeginDate,
                                                      callback: function ($$v) {
                                                        _vm.selectedBeginDate =
                                                          $$v
                                                      },
                                                      expression:
                                                        "selectedBeginDate",
                                                    },
                                                  },
                                                  "b-form-datepicker",
                                                  _vm.labels[_vm.locale] || {},
                                                  false
                                                )
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Data final",
                                                "label-for": "date-input",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-datepicker",
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      id: "example-datepicker2",
                                                      "reset-button": "",
                                                      locale: _vm.locale,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.onDateChange()
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedFinalDate,
                                                      callback: function ($$v) {
                                                        _vm.selectedFinalDate =
                                                          $$v
                                                      },
                                                      expression:
                                                        "selectedFinalDate",
                                                    },
                                                  },
                                                  "b-form-datepicker",
                                                  _vm.labels[_vm.locale] || {},
                                                  false
                                                )
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        [
                                          _c(
                                            "h5",
                                            {
                                              staticStyle: {
                                                "font-size": "10",
                                              },
                                            },
                                            [
                                              _vm._v("Total "),
                                              _c(
                                                "b-badge",
                                                { staticClass: "badge" },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.statementTotal)
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("download-csv", {
                                            ref: "csvExporter",
                                            attrs: {
                                              name: _vm.statementCsvTitle,
                                            },
                                            on: {
                                              "export-started": function (
                                                $event
                                              ) {
                                                return _vm.generateDataToExport()
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("b-col", [
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "b-form-radio-group",
                                              {
                                                attrs: { buttons: "" },
                                                model: {
                                                  value: _vm.selected,
                                                  callback: function ($$v) {
                                                    _vm.selected = $$v
                                                  },
                                                  expression: "selected",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-radio",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "b-tooltip",
                                                        rawName:
                                                          "v-b-tooltip.hover",
                                                        modifiers: {
                                                          hover: true,
                                                        },
                                                      },
                                                    ],
                                                    attrs: {
                                                      value: "user",
                                                      title:
                                                        "Usuário Responsável",
                                                    },
                                                  },
                                                  [_vm._v("Usuário")]
                                                ),
                                                _c(
                                                  "b-form-radio",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "b-tooltip",
                                                        rawName:
                                                          "v-b-tooltip.hover",
                                                        modifiers: {
                                                          hover: true,
                                                        },
                                                      },
                                                    ],
                                                    attrs: {
                                                      value: "cod",
                                                      title: "Código",
                                                    },
                                                  },
                                                  [_vm._v("Código")]
                                                ),
                                                _c(
                                                  "b-form-radio",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "b-tooltip",
                                                        rawName:
                                                          "v-b-tooltip.hover",
                                                        modifiers: {
                                                          hover: true,
                                                        },
                                                      },
                                                    ],
                                                    attrs: {
                                                      value: "description",
                                                      title:
                                                        "Descrição da auditoria",
                                                    },
                                                  },
                                                  [_vm._v("Descrição")]
                                                ),
                                                _c(
                                                  "b-form-radio",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "b-tooltip",
                                                        rawName:
                                                          "v-b-tooltip.hover",
                                                        modifiers: {
                                                          hover: true,
                                                        },
                                                      },
                                                    ],
                                                    attrs: {
                                                      value: "type",
                                                      title:
                                                        "Tipo da transação",
                                                    },
                                                  },
                                                  [_vm._v("Tipo")]
                                                ),
                                                _c(
                                                  "b-form-radio",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "b-tooltip",
                                                        rawName:
                                                          "v-b-tooltip.hover",
                                                        modifiers: {
                                                          hover: true,
                                                        },
                                                      },
                                                    ],
                                                    attrs: {
                                                      value: "duration",
                                                      title:
                                                        "Duração da transação",
                                                    },
                                                  },
                                                  [_vm._v("Duração")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "b-input-group",
                                                  {
                                                    staticStyle: {
                                                      "{padding-top": "10px",
                                                      "margin-top": "10px",
                                                    },
                                                    attrs: { size: "sm" },
                                                  },
                                                  [
                                                    _c(
                                                      "b-input-group-prepend",
                                                      {
                                                        attrs: {
                                                          "is-text": "",
                                                        },
                                                      },
                                                      [
                                                        _c("b-icon", {
                                                          attrs: {
                                                            icon: "search",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "search",
                                                        placeholder:
                                                          "Buscar...",
                                                      },
                                                      on: {
                                                        keyup: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          return _vm.onStatementSearchClick()
                                                        },
                                                      },
                                                      model: {
                                                        value: _vm.filterText,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.filterText = $$v
                                                        },
                                                        expression:
                                                          "filterText",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c("b-table", {
                                        staticStyle: {
                                          "{ max-height": "300px !important",
                                          height: "300px !important",
                                          "overflow-y": "scroll}",
                                        },
                                        attrs: {
                                          responsive: "",
                                          striped: "",
                                          fields: _vm.statementFields,
                                          items: _vm.statements,
                                          "per-page": 0,
                                          "current-page":
                                            _vm.currentStatementPage,
                                        },
                                        on: { "sort-changed": _vm.sortChanged },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "cell(api)",
                                              fn: function (row) {
                                                return [
                                                  _c("img", {
                                                    attrs: {
                                                      src: _vm.getApiIconStyle(
                                                        row.item
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1344666366
                                        ),
                                      }),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { staticClass: "my-3" },
                                            [
                                              _c("b-pagination", {
                                                staticClass: "my-0",
                                                attrs: {
                                                  "total-rows":
                                                    _vm.totalStatementRows,
                                                  "per-page":
                                                    _vm.perStatementPage,
                                                  align: "fill",
                                                  size: "sm",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.updatePage()
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.currentStatementPage,
                                                  callback: function ($$v) {
                                                    _vm.currentStatementPage =
                                                      $$v
                                                  },
                                                  expression:
                                                    "currentStatementPage",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { staticClass: "my-3" },
                                            [
                                              _c("b-form-select", {
                                                attrs: {
                                                  id: "per-page-select",
                                                  options: _vm.pageOptions,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.updatePage()
                                                  },
                                                },
                                                model: {
                                                  value: _vm.perStatementPage,
                                                  callback: function ($$v) {
                                                    _vm.perStatementPage = $$v
                                                  },
                                                  expression:
                                                    "perStatementPage",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-tab",
                        {
                          attrs: { title: "Extrato PF" },
                          on: {
                            click: function ($event) {
                              return _vm.onBonusTabClick()
                            },
                          },
                        },
                        [
                          _c(
                            "b-col",
                            { staticClass: "my-1", attrs: { lg: "6" } },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-form-radio-group",
                                    {
                                      attrs: { buttons: "" },
                                      model: {
                                        value: _vm.userRadioBonusSelected,
                                        callback: function ($$v) {
                                          _vm.userRadioBonusSelected = $$v
                                        },
                                        expression: "userRadioBonusSelected",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-radio",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover",
                                              modifiers: { hover: true },
                                            },
                                          ],
                                          attrs: {
                                            value: "user",
                                            title: "Usuário Responsável",
                                          },
                                        },
                                        [_vm._v("Usuário")]
                                      ),
                                      _c(
                                        "b-form-radio",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover",
                                              modifiers: { hover: true },
                                            },
                                          ],
                                          attrs: {
                                            value: "cod",
                                            title: "Código",
                                          },
                                        },
                                        [_vm._v("Código")]
                                      ),
                                      _c(
                                        "b-form-radio",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover",
                                              modifiers: { hover: true },
                                            },
                                          ],
                                          attrs: {
                                            value: "name",
                                            title: "Nome",
                                          },
                                        },
                                        [_vm._v("Nome")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-input-group",
                                    {
                                      staticStyle: {
                                        "{padding-top": "10px",
                                        "margin-top": "10px",
                                      },
                                      attrs: { size: "sm" },
                                    },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        { attrs: { "is-text": "" } },
                                        [
                                          _c("b-icon", {
                                            attrs: { icon: "search" },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("b-form-input", {
                                        attrs: {
                                          type: "search",
                                          placeholder: "Buscar...",
                                        },
                                        on: {
                                          keyup: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            return _vm.onUserBonusSearchClick()
                                          },
                                        },
                                        model: {
                                          value: _vm.filterUserBonusText,
                                          callback: function ($$v) {
                                            _vm.filterUserBonusText = $$v
                                          },
                                          expression: "filterUserBonusText",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("b-table", {
                            attrs: {
                              striped: "",
                              fields: _vm.userFields,
                              "current-page": _vm.currentPage,
                              items: _vm.users,
                              "per-page": 0,
                              stacked: "md",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "cell(info)",
                                  fn: function (row) {
                                    return [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "item-table",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onInfoClick(
                                                    row.item,
                                                    row.index,
                                                    $event.target
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("+")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3816454502
                            ),
                          }),
                          _c(
                            "b-col",
                            {
                              staticClass: "my-1",
                              attrs: { sm: "7", md: "6" },
                            },
                            [
                              _c("b-pagination", {
                                staticClass: "my-0",
                                attrs: {
                                  "total-rows": _vm.totalRows,
                                  "per-page": _vm.perPage,
                                  align: "fill",
                                  size: "sm",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.updateBonusPage()
                                  },
                                },
                                model: {
                                  value: _vm.currentPage,
                                  callback: function ($$v) {
                                    _vm.currentPage = $$v
                                  },
                                  expression: "currentPage",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-modal",
                            {
                              ref: "corporateModal",
                              attrs: {
                                id: "modal-prevent-closing",
                                "ok-title": "Ok",
                                "cancel-variant": "transparent",
                                title: _vm.corporateModalTitle,
                                size: "xl",
                              },
                              on: {
                                hidden: _vm.resetCorporateModal,
                                ok: _vm.handleCorporateModalOk,
                              },
                            },
                            [
                              _c(
                                "b-form",
                                {
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Data inicial",
                                                "label-for": "date-input",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-datepicker",
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      id: "example-datepicker",
                                                      "reset-button": "",
                                                      locale: _vm.locale,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.onDateChange()
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedBeginDate,
                                                      callback: function ($$v) {
                                                        _vm.selectedBeginDate =
                                                          $$v
                                                      },
                                                      expression:
                                                        "selectedBeginDate",
                                                    },
                                                  },
                                                  "b-form-datepicker",
                                                  _vm.labels[_vm.locale] || {},
                                                  false
                                                )
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Data final",
                                                "label-for": "date-input",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-datepicker",
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      id: "example-datepicker2",
                                                      "reset-button": "",
                                                      locale: _vm.locale,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.onDateChange()
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedFinalDate,
                                                      callback: function ($$v) {
                                                        _vm.selectedFinalDate =
                                                          $$v
                                                      },
                                                      expression:
                                                        "selectedFinalDate",
                                                    },
                                                  },
                                                  "b-form-datepicker",
                                                  _vm.labels[_vm.locale] || {},
                                                  false
                                                )
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        [
                                          _c(
                                            "h5",
                                            {
                                              staticStyle: {
                                                "font-size": "10",
                                              },
                                            },
                                            [
                                              _vm._v("Total "),
                                              _c(
                                                "b-badge",
                                                { staticClass: "badge" },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.statementTotal)
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("download-csv", {
                                            ref: "csvExporter",
                                            attrs: {
                                              name: _vm.statementCsvTitle,
                                            },
                                            on: {
                                              "export-started": function (
                                                $event
                                              ) {
                                                return _vm.generateDataToExport()
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("b-col", [
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "b-form-radio-group",
                                              {
                                                attrs: { buttons: "" },
                                                model: {
                                                  value: _vm.selected,
                                                  callback: function ($$v) {
                                                    _vm.selected = $$v
                                                  },
                                                  expression: "selected",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-radio",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "b-tooltip",
                                                        rawName:
                                                          "v-b-tooltip.hover",
                                                        modifiers: {
                                                          hover: true,
                                                        },
                                                      },
                                                    ],
                                                    attrs: {
                                                      value: "user",
                                                      title:
                                                        "Usuário Responsável",
                                                    },
                                                  },
                                                  [_vm._v("Usuário")]
                                                ),
                                                _c(
                                                  "b-form-radio",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "b-tooltip",
                                                        rawName:
                                                          "v-b-tooltip.hover",
                                                        modifiers: {
                                                          hover: true,
                                                        },
                                                      },
                                                    ],
                                                    attrs: {
                                                      value: "cod",
                                                      title: "Código",
                                                    },
                                                  },
                                                  [_vm._v("Código")]
                                                ),
                                                _c(
                                                  "b-form-radio",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "b-tooltip",
                                                        rawName:
                                                          "v-b-tooltip.hover",
                                                        modifiers: {
                                                          hover: true,
                                                        },
                                                      },
                                                    ],
                                                    attrs: {
                                                      value: "description",
                                                      title:
                                                        "Descrição da auditoria",
                                                    },
                                                  },
                                                  [_vm._v("Descrição")]
                                                ),
                                                _c(
                                                  "b-form-radio",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "b-tooltip",
                                                        rawName:
                                                          "v-b-tooltip.hover",
                                                        modifiers: {
                                                          hover: true,
                                                        },
                                                      },
                                                    ],
                                                    attrs: {
                                                      value: "type",
                                                      title:
                                                        "Tipo da transação",
                                                    },
                                                  },
                                                  [_vm._v("Tipo")]
                                                ),
                                                _c(
                                                  "b-form-radio",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "b-tooltip",
                                                        rawName:
                                                          "v-b-tooltip.hover",
                                                        modifiers: {
                                                          hover: true,
                                                        },
                                                      },
                                                    ],
                                                    attrs: {
                                                      value: "duration",
                                                      title:
                                                        "Duração da transação",
                                                    },
                                                  },
                                                  [_vm._v("Duração")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "b-input-group",
                                                  {
                                                    staticStyle: {
                                                      "{padding-top": "10px",
                                                      "margin-top": "10px",
                                                    },
                                                    attrs: { size: "sm" },
                                                  },
                                                  [
                                                    _c(
                                                      "b-input-group-prepend",
                                                      {
                                                        attrs: {
                                                          "is-text": "",
                                                        },
                                                      },
                                                      [
                                                        _c("b-icon", {
                                                          attrs: {
                                                            icon: "search",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "search",
                                                        placeholder:
                                                          "Buscar...",
                                                      },
                                                      on: {
                                                        keyup: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          return _vm.onStatementSearchClick()
                                                        },
                                                      },
                                                      model: {
                                                        value: _vm.filterText,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.filterText = $$v
                                                        },
                                                        expression:
                                                          "filterText",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c("b-table", {
                                        staticStyle: {
                                          "{ max-height": "300px !important",
                                          height: "300px !important",
                                          "overflow-y": "scroll}",
                                        },
                                        attrs: {
                                          responsive: "",
                                          striped: "",
                                          fields: _vm.statementFields,
                                          items: _vm.statements,
                                          "per-page": 0,
                                          "current-page":
                                            _vm.currentStatementPage,
                                        },
                                        on: { "sort-changed": _vm.sortChanged },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "cell(api)",
                                              fn: function (row) {
                                                return [
                                                  _c("img", {
                                                    attrs: {
                                                      src: _vm.getApiIconStyle(
                                                        row.item
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1344666366
                                        ),
                                      }),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { staticClass: "my-3" },
                                            [
                                              _c("b-pagination", {
                                                staticClass: "my-0",
                                                attrs: {
                                                  "total-rows":
                                                    _vm.totalStatementRows,
                                                  "per-page":
                                                    _vm.perStatementPage,
                                                  align: "fill",
                                                  size: "sm",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.updatePage()
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.currentStatementPage,
                                                  callback: function ($$v) {
                                                    _vm.currentStatementPage =
                                                      $$v
                                                  },
                                                  expression:
                                                    "currentStatementPage",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { staticClass: "my-3" },
                                            [
                                              _c("b-form-select", {
                                                attrs: {
                                                  id: "per-page-select",
                                                  options: _vm.pageOptions,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.updatePage()
                                                  },
                                                },
                                                model: {
                                                  value: _vm.perStatementPage,
                                                  callback: function ($$v) {
                                                    _vm.perStatementPage = $$v
                                                  },
                                                  expression:
                                                    "perStatementPage",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }