var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: _vm.idName }, on: { click: _vm.generate } },
    [
      _c(
        "b-button",
        [
          _vm._t("default", function () {
            return [_vm._v("Download " + _vm._s(_vm.name))]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }